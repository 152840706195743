<template>
  <div id="table-member-approve">
    <v-card>
      <v-app-bar
        color="primary"
        dense
        dark
        elevate-on-scroll
        scroll-target="#scrolling-techniques-7"
      >
        <h3>
          <b style="margin-left: 30px; color: white"> {{ pageTitle }}</b>
        </h3>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="closePrompt">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-app-bar>
      <v-container style="overflow: hidden">
        <v-card-title></v-card-title>
        <v-card-text>
          <div class="d-flex justify-content-center mb-3" v-if="LoadingFlag">
            <b-spinner
              variant="primary"
              type="grow"
              label="Loading..."
            ></b-spinner>
          </div>
          <v-form
            ref="form1"
            v-model="valid1"
            lazy-validation
            v-on:submit.prevent="confirmAlert"
          >
            <v-container>
              <v-row wrap>
                <v-row wrap>
                  <v-col cols="12" sm="6" lg="3" md="3">
                    <h5 class="blue--text text--lighten">Event Name</h5>
                    <p class="preview-content">
                      {{ recordData.EventName }}
                    </p>
                  </v-col>
                  <v-col cols="12" sm="6" lg="3" md="3">
                    <h5 class="blue--text text--lighten">Zone Name</h5>
                    <p class="preview-content">{{ recordData.ZoneName }}</p>
                  </v-col>
                  <v-col cols="12" sm="6" lg="3" md="3">
                    <h5 class="blue--text text--lighten">LO Name</h5>
                    <p class="preview-content">{{ recordData.LoName }}</p>
                  </v-col>
                  <v-col cols="12" sm="6" lg="3" md="3">
                    <h5 class="blue--text text--lighten">Proposed Venue</h5>
                    <p class="preview-content">{{ recordData.VenueDetails }}</p>
                  </v-col>
                  <v-col cols="12" sm="6" lg="3" md="3">
                    <h5 class="blue--text text--lighten">Proposal Dates</h5>
                    <p class="preview-content">
                      {{ recordData.ProposalDates }}
                    </p>
                    <template v-slot:recordData.StatusTxt="{ recordData }">
                      <v-chip :color="recordData.StatusTxt" draggable dark>{{
                        recordData.StatusTxt
                      }}</v-chip>
                    </template>
                  </v-col>
                  <v-col cols="12" sm="6" lg="3" md="3">
                    <h5 class="blue--text text--lighten">Active Status</h5>
                    <div>
                      <v-chip :color="recordData.StatusColor" draggable>{{
                        recordData.StatusTxt
                      }}</v-chip>
                    </div>
                  </v-col>
                  <v-col cols="12" lg="12" sm="12" md="12">
                    <!-- <v-divider /> -->
                    <label>
                      <h3><span class="text-danger">*</span>Reason</h3>
                      <h4 v-html="recordData.Description"></h4>
                    </label>
                    <v-textarea
                      outlined
                      v-model="Reason"
                      :rules="ReasonRules"
                      name="input-7-4"
                      label="Reason"
                      value=""
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-row>
              <v-row wrap>
                <v-col align="center" cols="12" md="12">
                  <v-btn
                    :disabled="!valid1"
                    @click.prevent="confirmAlert(1)"
                    :loading="SubmitFlag"
                    elevation="30"
                    shaped
                    tile
                    medium
                    color="#1db954"
                    class="font-size-h6 px-6 mr-3 white--text"
                  >
                    Approved
                  </v-btn>
                  <v-btn
                    :disabled="!valid1"
                    @click.prevent="confirmAlert(3)"
                    :loading="SubmitFlag"
                    elevation="30"
                    shaped
                    tile
                    medium
                    color="warning"
                    class="font-size-h6 px-6 mr-3 white--text"
                  >
                    Rejected
                  </v-btn>
                  <v-btn
                    @click="closePrompt"
                    elevation="30"
                    shaped
                    tile
                    medium
                    color="#a52a2a"
                    class="font-size-h6 px-6 mr-3 white--text"
                  >
                    Close
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import Swal from "sweetalert2";

export default {
  mixins: [common],
  components: {},
  props: {
    scoreRecordPrompt: {
      type: Boolean,
      required: true,
    },
    recordData: {
      required: true,
    },
    pageTitle: {
      type: String,
    },
    pageDescription: {
      type: String,
    },
  },
  data() {
    return {
      tableColumns1: [],
      tableOptions1: {},
      tableData1: [],

      valid1: true,

      row: {},
      ResultFlag: false,

      PreviewButtonFlag: false,
      Documentpath: "",
      ResultFlag: false,

      LoadingFlag: false,

      TableFlag1: false,

      selected: [],
      SearchFlag: false,
      search: "",

      ChairmanData: {},
      DirectorData: {},
      DocumentsData: [],
      FacultiesData: [],

      EfficiencyIdRules: [(v) => !!v || "100% Efficiency criteria is required"],
      EfficiencyId: "",
      EfficiencyIdOptions: [],
      EfficiencyIdOptionsLoading: false,

      Remarks: "",
      RemarksRules: [(v) => !!v || "Remarks is required"],

      ApproveLoadingFlag: false,
      RejectLoadingFlag: false,
      FilePreviewFlag: false,

      LomDataPageTitle: "",
      LomDataPreviewFlag: false,
      LomDataParams: {},

      Reason: "",
      ReasonRules: [(v) => !!v || "Reason is required"],
      menu1: false,
    };
  },
  computed: {},
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    row: function () {
      console.log("watch row");
      var tr = this.row;
      // var n1 = tr.length
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));
      this.EfficiencyId = "";
      if (n1 > 0) {
        this.EfficiencyId = tr.EfficiencyId;
        this.getEfficiencyIdOptions();
      }
    },
    EfficiencyIdOptions: function () {
      console.log("watch EfficiencyIdOptions");
      this.LoadingFlag = false;
      this.EfficiencyIdOptionsLoading = false;
    },
  },
  created() {
    // this.initialize()
  },
  methods: {
    closePrompt() {
      console.log("closePrompt called");
      this.$emit("hidePreviewRecordPrompt", this.ResultFlag);
    },
    validate() {
      console.log("validate is called");
      var result = this.$refs.form.validate();
      console.log("result=" + result);
      if (result) {
        this.addRecordDetails();
      } else {
        console.log("some errors occured");
      }
    },
    refreshPageData() {
      console.log("refreshPageData called");
      var recordData = this.recordData;
      console.log("recordData=" + JSON.stringify(recordData));
      this.getTableRecords();
      var recordData = this.recordData;
      console.log("recordData = " + JSON.stringify(recordData));
    },

    confirmAlert(status) {
      console.log({ status });
      var validate1 = this.$refs.form1.validate();
      console.log({ validate1 });

      if (validate1) {
        Swal.fire({
          title: "Do you want to continue?",
          text: "Please verify all the information before proceeding.",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: `Continue`,
          confirmButtonColor: "#3085d6",
          cancelButtonText: `Cancel`,
          cancelButtonColor: "#d33",
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.updateActiveStatus(status);
          } else {
            this.sweetAlert("error", "You cancelled the process", true);
          }
        });
      } else {
        var message = "Kindly fill the required fields";
        this.sweetAlert("error", message);
      }
    },
    updateActiveStatus(status) {
      console.log("updateActiveStatus is called");

      var validate1 = this.$refs.form1.validate();
      console.log({ validate1 });

      var Status = status;
      var Reason = this.Reason;
      var tr = this.recordData;
      console.log({ tr });

      var ProgramBid = this.ProgramBid;
      console.log({ Status }, { Reason }, { ProgramBid });

      if (validate1) {
        this.LoadingFlag = true;
        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/events/bidding/event-status-update";
        var upload = {
          UserInterface: 1,
          Status: Status,
          Reason: Reason,
          ProgramBid: tr.ProgramBid,
          FromDate: tr.FromDate,
          ToDate: tr.ToDate,
          ProgramType: tr.ProgramTypeId,
          CityName: tr.CityId,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));
            thisIns.LoadingFlag = false;

            flag = response.data.flag;
            output = response.data.output;
            console.log("output=" + output + ", flag=" + flag);

            records = response.data.records;
            console.log({ records });

            if (flag == 1) {
              thisIns.sweetAlert("success", output, true);
              thisIns.ResultFlag = true;
              thisIns.closePrompt();
            } else {
              thisIns.sweetAlert("error", output, true);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.LoadingFlag = false;
          });
      } else {
        var message = "Kindly fill the required fields";
        this.sweetAlert("error", message);
      }
    },
  },
  beforeMount() {
    this.refreshPageData();
  },
};
</script>
<style lang="scss">
.preview-content {
  font-size: 18px;
  font-weight: normal;
  line-height: 1.5em;
}
</style>